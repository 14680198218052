import React from 'react'
import './roundedCheckBox.scss'

const RoundedCheckBox = ({ value, onChange, className }) => {
    const [val, setV] = React.useState(false)
    React.useEffect(() => {
        let _ = onChange?.(val)
    }, [val])
    return (
        <div className={"rounded-check-box " + (className ?? '')} onClick={() =>setV(!(value || val))}>
            <div className={(value || val) ? 'on' : 'off'}></div>
        </div>
    )
}

export default RoundedCheckBox
