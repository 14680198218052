import React from 'react'
import ReactDOM from 'react-dom'
import './popupwrapper.scss'

const Popup = ({ children, open = false, close, className, closeCross }) => {
    React.useEffect(() => {
        open && document.body.classList.add('block-scroll')
        return () => document.body.classList.remove('block-scroll')
    }, [open])
    if (open !== true || !children) return <></>
    const Wrapped = () => <div className={"popup-overlay " + (className || '')} onClick={e => e.stopPropagation() || close()}>
        <div className={"popup-content " + (className || '')} onClick={e => e.stopPropagation()}>
            {closeCross && <a className="close circle" onClick={close}>&times;</a>}
            {
                children instanceof React.Component || typeof children === 'function' ?
                    React.cloneElement(children, { close }) :
                    children
            }
        </div>
    </div>
    let container = document.querySelector('#popup-root')
    return ReactDOM.createPortal(<Wrapped />, container)
}

export default Popup