import React, { createContext, useContext, useEffect } from 'react'
import { useDiffState } from '@oriun/hookster';

const MainContext = createContext({})

export const Provider = ({ children }) => {
    const [state, setState] = useDiffState(JSON.parse(localStorage.getItem("context") || "{}"))
    useEffect(() => {
        localStorage.setItem("context", JSON.stringify(state))
        window.logContext = (label = 'logcontext') => console.log(label, state)
    }, [state])
    return <MainContext.Provider value={{ state, setState }}>{children}</MainContext.Provider>
}
export const useMainContext = () => {
    const context = useContext(MainContext)
    return [context.state, context.setState]
}

export default MainContext