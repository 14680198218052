const RequestWithBody = method => (
    url,
    body = {},
    headers = { 'Content-Type': 'application/json' },
    jsonBody = true
) => fetch(url, {
    method: method,
    headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
    body: jsonBody ? JSON.stringify(body) : body
})
    .then(response => response.json())

export const POST = RequestWithBody('POST')
export const PUT = RequestWithBody('PUT')
export const PATCH = RequestWithBody('PATCH')
export const DELETE = RequestWithBody('DELETE')

// export const DELETE = (url, headers = {}) => fetch(url, {
//     method: "DELETE",
//     headers: Object.assign({ 'Content-Type': 'application/json' }, headers)
// })
//     .then(response => response.json())
export const GET = (url, headers = {}) => fetch(url, {
    headers: Object.assign({ 'Content-Type': 'application/json' }, headers)
})
    .then(response => response.json())

