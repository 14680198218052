import { GET, POST, PUT, PATCH, DELETE } from "../helpers/fetch";
import { APISAILS_URL } from "./Config"
import { authPromise } from "./authBrowser";

const defaultHeaders = (token) => ({
    Authorization: "Bearer " + token,
    "Content-Type": "application/json"
})

export const login = (mail, password) => {
    return POST(APISAILS_URL + '/userpro/login', { mail, password }).then(res => {
        return res
    })
}

export const getCompanyUsers = (id) => {
    return authPromise.then(token => GET(`${APISAILS_URL}/users/company/${id}`, defaultHeaders(token))).then(res => {
        return res
    })
}

export const joinCompany = (user_id, company_id) => {
    return authPromise.then(token => POST(`${APISAILS_URL}/companyuser`, { company_id, user_id }, defaultHeaders(token))).then(res => {
        console.log(res)
        return res
    })
}

export const leaveCompany = (user_id) => {
    return authPromise.then(token => POST(`${APISAILS_URL}/userpro/company/remove`, {user_id}, defaultHeaders(token))).then(res => {
        console.log(res)
        return res
    })
}

export const getUserId = (email) => {
    return authPromise.then(token => GET(`${APISAILS_URL}/userpro?where={"email": "${email}"}`, defaultHeaders(token)))
        .then(res => res.rows[0])
}

export const getAccounts = (id) => {
    return authPromise.then(token => GET(`${APISAILS_URL}/company/${id}/apiAccounts`, defaultHeaders(token)))
        .then(res => res)
}

export const getAppSecret = (id) => {
    return authPromise.then(token => GET(`${APISAILS_URL}/apiAccount?where={"company_id":"${id}"}`, defaultHeaders(token)))
        .then(res => res.rows)
}

export function askForPasswordReset(email) {
    return authPromise.then(token => POST(APISAILS_URL + "/userpro/passwordlost",
        { email },
        defaultHeaders(token)
    ))
}
export function resetPassword(userId, password, password_check) {
    return authPromise.then(token => PUT(`${APISAILS_URL}/userpro/${userId}/resetpassword`,
        { password, password_check },
        defaultHeaders(token)
    ))
}
export function updatePassword(userId, old_password, password, password_check) {
    return authPromise.then(token => PUT(`${APISAILS_URL}/userpro/${userId}/changepassword`,
        { old_password, password, password_check },
        defaultHeaders(token)
    ))
}

export function getSessionInfo(sessionId) {
    return authPromise.then(token => GET(`${APISAILS_URL}/companyusersession/${sessionId}`,
        defaultHeaders(token)
    ))
}

export const createApiAccount = async (params) => {
    let id;
    const tokenHeader = await authPromise.then(defaultHeaders)
    return POST(APISAILS_URL + '/apiAccount', params, tokenHeader)
        .then(res => { id = res.id })
        // .then(() => PATCH(`${APISAILS_URL}/apiAccount/${id}/getAppSecret`, { password: params.password }, tokenHeader))
        .then(res => PATCH(`${APISAILS_URL}/apiAccount/${id}`, { active: true, app_secret: res, isFreemium: true, type: "customer_api" }, tokenHeader))
        .then(res => {
            POST(APISAILS_URL + '/whiteList', { api_account_id: id, url: params.url, active: true }, tokenHeader)
            return res
        })
}

export const registerAll = (params) => {
    let user
    return authPromise.then(token => POST(APISAILS_URL + '/userpro/register', params.user, defaultHeaders(token))
        .then(res => {
            user = res
            user.email = params.user.email
            user.job = params.user.job
            updateRegisterUser({ user: params.user, id: res.id })
            return registerCompany({ id: res.id || params.id, company: params.company })
        }).then(res => {
            addUserSendingBlue(user)
            sendTimenjoyMessages(params)
            return res
        }))
}

const updateRegisterUser = (params) => {
    delete params.user.password
    delete params.user.password_check
    authPromise.then(token => PUT(APISAILS_URL + '/userpro/' + params.id, params.user, defaultHeaders(token)))
}

export const sendInviteEmails = (emails, company, url) => {
    return authPromise.then(token => POST(`${APISAILS_URL}/sendInvites`, {emails, company, url}, defaultHeaders(token)).then(res => {
        console.log(res)
        return res
    }))
}

export const userJoinCompany = (nuser, company_id) => {
    return authPromise.then(token => POST(APISAILS_URL + '/userpro/register', nuser, defaultHeaders(token))
        .then(async res => {
            updateRegisterUser({ user: nuser, id: res.id })
            return res
        }).then(res => {
            return joinCompany(res.id, company_id).then(res => {
                return res
            })
        })
    )
}

const registerCompany = async (params) => {
    const tokenHeader = await authPromise.then(defaultHeaders)
    return POST(APISAILS_URL + '/company', params.company, tokenHeader)
        .then(res => {
            POST(APISAILS_URL + '/companyuser', { company_id: res.id, user_id: params.id }, tokenHeader)
            params.company.id = res.id
            updateCompany(params.company)
            return res
        })
}

const updateCompany = (company) => {
    return authPromise.then(token => PUT(`${APISAILS_URL}/company/${company.id}`, company, defaultHeaders(token)))
}

export const updateInfos = async (params) => {
    let firstRes = await updateCompany(params.company)
    let res = await updateUser(params.user)
    return [res, firstRes]
}

const updateUser = (user) => {
    return authPromise.then(token => PUT(`${APISAILS_URL}/userpro/${user.id}`, user, defaultHeaders(token)))
}

const addUserSendingBlue = async (user) => {
    const tokenHeader = await authPromise.then(defaultHeaders)
    return POST(APISAILS_URL + '/sendinblue/contacts', { listId: 8, user }, tokenHeader)
        .then(() => POST(APISAILS_URL + '/sendinblue/sendEmailTemplate', { emailTo: user.email, templateId: 452 }, tokenHeader))
}

const sendTimenjoyMessages = async (params) => {

    const html = `<p>La société <b>${params.company.name}</b> s'est enregistrée sur Mytimenjoy</p>\n
    <p>Identité: ${params.user.firstname + " " + params.user.lastname}. </p>\n
    <p>Email: ${params.user.email} </p>\n
    <p>Tél Pro: ${params.user.phone} \n
    <p>Tél Perso: ${params.user.phone} \n
    <p>Localisation: ${params.company.zip_code + " " + params.company.city}\n`

    const tokenHeader = await authPromise.then(defaultHeaders)

    POST(APISAILS_URL + '/sendinblue/sendEmail',
        {
            htmlContent: html,
            sender: { email: params.user.email, name: "My Time N'Joy" },
            subject: `La société ${params.company.name} s'est enregistrée sur MyTimeNJoy`,
            user: { email: "sales-support@timenjoy.fr" }
        }, tokenHeader)

    POST(APISAILS_URL + '/slack/message', {
        company_name: params.company.name,
        email: params.user.email,
        first_name: params.user.firstname,
        last_name: params.user.lastname,
        localisation: params.company.zip_code + " " + params.company.city,
        mytimenjoy_register: true,
        phone: params.user.phone
    }, tokenHeader)

}
