import React, { useState } from 'react'
import { login, askForPasswordReset, getAccounts } from '../../services/UserService'
import { useMainContext } from '../../contexts/main'
import { useHistory } from 'react-router-dom'
import './Popup.scss'
import PassWordInput from '../UI/PasswordInput'

const Login = ({ switchComponent }) => {
    const [mail, setEmail] = useState()
    const [password, setPassword] = useState()
    const [error, setError] = useState()
    const [, setContext] = useMainContext()
    const history = useHistory()

    const submit = () => {
        login(mail, password).then(res => {
            if (res.user && process.env.REACT_APP_API_ENABLE === 'true') {
                getAccounts(res.company.id).then(account => {
                    if (account) {
                        setContext({ account: account, user: res.user, company: res.company, selected: account.find?.(acc=>acc.app_secret) })
                        history.push('/app')
                    }
                    else {
                        setContext({})
                        setContext({ user: res.user, company: res.company })
                        history.push('/app')
                    }
                })
            }
            else if (res.user) {
                setContext({ user: res.user, company: res.company })
                history.push('/app')
            }
            else {
                if (res.message === "missing parameter : mail") {
                    setError("Veuillez entrer un mail")
                }
                else if (res.message === "Utilisateur non reconnu, enregistrez vous.") {
                    setError("Aucun compte n'est lié à ce mail")
                }
                else {
                    setError("Mot de passe incorrect")
                }
            }
        })
    }
    return (<>
        <h1>Bonjour,</h1>
        <p>Ravi de vous revoir, saisissez vos informations pour vous connecter</p>
        <div>
            <input type="text" placeholder="Adresse mail" value={mail} onChange={e => setEmail(e.target.value)}></input>
        </div>
        <div>
            <PassWordInput autocomplete="on" placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)} />
            {/* <input type="text" placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)}></input> */}
        </div>
        <span style={{ cursor: 'pointer' }} onClick={() => switchComponent(Reset)}>Mot de passe oublié ?</span>
        <button onClick={() => submit()}>Se connecter</button>
        {error && <label style={{ color: "red" }}>{error}</label>}
    </>)
}

const Reset = ({ switchComponent }) => {
    const [mail, setEmail] = useState()
    const [error, setError] = useState()

    function submit() {
        askForPasswordReset(mail)
            .then(res => {
                setError(false)
            })
            .catch(err => window.alert(JSON.stringify(err)))
    }
    return (<>
        <h1>Un oubli ?</h1>
        <p>Réinitialiser votre mot de passe</p>
        <div>
            <input type="text" placeholder="Adresse mail" value={mail} onChange={e => setEmail(e.target.value)}></input>
        </div>
        {error === false && <>
            <p>
                C’est envoyé !<br />
                <span>
                    Vérifiez vos mails pour suivre la procédure de réinitialisation de votre mot de passe.
                </span>
            </p>
        </>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button onClick={() => submit()}>Réinitialiser mon mot de passe</button>
        <span>Je m'en souviens, <a onClick={() => switchComponent(Login)}>me connecter</a></span>
    </>)
}
const Popup = ({ setPopup, setValue }) => {

    const [Component, setComponent] = useState(() => Login)
    const history = useHistory()

    return (
        <div className="popup-overlay" onClick={e => { setPopup(false); setValue(false) }}>
            <div className="popup" onClick={e => e.stopPropagation()}>
                <div className="popup-elements">
                    <Component switchComponent={a => setComponent(() => a)} />
                </div>
                <div className="right-elements">
                    <h1>Pas de compte ?</h1>
                    <p>My Timenjoy vous permet de gérer, distribuer et communiquer vos évènements sur une seule et unique plate-forme. </p>
                    <button onClick={() => history.push('/register')}>S'inscrire gratuitement</button>
                </div>
            </div>
        </div>
    )
}

export default Popup