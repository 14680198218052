import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './register.scss'
import { useHistory } from 'react-router'
import { useMainContext } from '../../contexts/main'
import { getUserId, login, userJoinCompany, getAccounts } from '../../services/UserService'
import RoundedCheckbox from '../../components/UI/RoundedCheckBox/roundedCheckBox'
import PassWordInput from '../../components/UI/PasswordInput'
import Errors from '../../components/UI/Errors/Errors'
import CryptoJS from 'crypto-js'


const apiBlocked = process.env.REACT_APP_API_ENABLE !== 'true'

const LegalParagraphe = () => (
    <p style={{ textAlign: "justify", marginTop: 16, fontSize: '.8rem', opacity: .75 }}>
        Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, et vous pouvez, pour des motifs légitimes, vous opposer à leur traitement en vous adressant à <a href="mailto:info@timenjoy.fr">info@timenjoy.fr</a>. <a href="https://timenjoy.fr">timenjoy.fr</a> ne divulguera en aucun cas vos informations personnelles à des tiers sans votre accord préalable. Consulter notre politique de confidentialité.
    </p>
)

const Join = () => {
    const [user, setUser] = useState({})
    let params = useParams()
    const [company, setC] = useState({})
    const [disabled, setDisabled] = useState(true)
    const [checked, setChecked] = useState(false)
    const [error, setError] = useState()
    const [loading, setL] = useState(false)

    const [context, setContext] = useMainContext()
    const history = useHistory()

    useEffect(() => {
        (Object.keys(user).length < 7 || !checked) || ((user.password?.current !== user.password_check?.current) || (user.password !== user.password_check)) ? setDisabled(true) : setDisabled(false)
    }, [user, checked])

    useEffect(() => {
        let encrypted = params.id.replaceAll('azertyuiop', '/')
        let decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_ENCRYPT_SECRET)
        decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
        setC({ ...company, id: decrypted.id, name: decrypted.name })
    }, [])

    const handleChange = e => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const isExisting = () => {
        let pwd = user.password
        login(user.email, user.password?.current || user.password).then(res => {
            if (res.message === "Utilisateur non reconnu, enregistrez vous.") {
                setL(true)
                userJoinCompany(user, company.id).then(res => {
                    if (res.company_id && res.id && res.user_id) {
                        login(user.email, pwd).then(res => {
                            if (res.company && res.token && res.user) {
                                setContext(res)
                                getAccounts(res.company.id).then(res => {
                                    setContext({ ...context, account: res, selected: res })
                                    history.push('/app')
                                })
                            }
                            else {
                                setError([["Un problème a été rencontré, veuillez ressaissir vos informations", false]])
                            }
                        })
                    }
                    else {
                        setError([["Un problème a été rencontré, veuillez ressaissir vos informations", false]])
                    }
                })
            }
            else {
                setError([["Un utilisateur est déjà lié à cet email", false]])
            }
        })
    }

    if (!loading) return (
        <>
            <div className="register-part">
                <img src="/img/mytj-logo.png" alt="tnj logo" onClick={() => history.push('/')}></img>

                <div className="register-card">
                    <h1>Vous avez été invité à rejoindre l'équipe {company?.name}</h1>
                    <h2>Avant de commencer, des informations sont nécessaires.</h2>
                    {/* {error && <h3>{error}</h3>} */}

                    <>
                        <div className="separation" />
                        <div className="register-form">
                            <div>
                                <h4>Adresse mail</h4>
                                <input type="text" name="email" value={user.email || ""} placeholder="Adresse mail" onChange={handleChange}></input>
                            </div>
                            <div>
                                <h4>Prénom</h4>
                                <input type="text" name="firstname" value={user.firstname || ""} placeholder="Prénom" onChange={handleChange}></input>
                            </div>
                            <div>
                                <h4>Nom </h4>
                                <input type="text" name="lastname" value={user.lastname || ""} placeholder="Nom" onChange={handleChange}></input>
                            </div>
                            <div>
                                <h4>Fonction</h4>
                                <input type="text" name="job" value={user.job || ""} placeholder="Fonction" onChange={handleChange}></input>
                            </div>
                            <div>
                                <h4>Téléphone</h4>
                                <input type="text" name="phone" value={user.phone || ""} placeholder="Téléphone" onChange={handleChange}></input>
                            </div>
                            <div>
                                <h4>Mot de passe</h4>
                                <PassWordInput autocomplete="off" name="password" placeholder="Mot de passe" onChange={handleChange} />
                            </div>
                            <div>
                                <h4>Confirmez le mot de passe</h4>
                                <PassWordInput autocomplete="off" name="password_check" placeholder="Confirmez le mot de passe" onChange={handleChange} />
                            </div>
                            <div />
                            <div />
                            <div className="checkbox">
                                <RoundedCheckbox value={checked} onChange={setChecked} />
                                <h4>J'accepte les termes des <a href="/terms/cgu" target="_blank" >conditions générales d'utilisation</a></h4>
                            </div>
                        </div>
                    </>

                    <div className="line-buttons">
                        <span />
                        <button disabled={disabled} className={disabled ? "timenjoy disabled" : "timenjoy"} onClick={() => isExisting()}>Enregistrer le compte</button>
                    </div>
                    <LegalParagraphe />
                </div>
                <div className="errors-boxes">
                    {error && error.map((err, i) => <Errors message={error[i][0]} type={error[i][1]} />)}
                </div>
            </div>
        </>
    ) 
    else return <div>
    </div>
}

export default Join