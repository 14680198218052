import React, { Suspense, lazy, useEffect } from 'react'
import './App.scss'
import { BrowserRouter, Switch, Route, Redirect, useHistory } from "react-router-dom"
import { Provider, useMainContext } from './contexts/main'
import Join from './views/Register/join'
import DesignGuidelines from './components/Guidelines'
import Home from './views/Home/home';
import HomeAPI from './views/Home_api/home';
import HomeStudio from './views/Home_studio/home';
import HomePerf from './views/Home_perf/home';

const Reset = lazy(() => import('./views/Reset'))
const Main = lazy(() => import('./views/Main/main'))
// const Home = lazy(() => import('./views/Home/home'))
// const HomeAPI = lazy(() => import('./views/Home_api/home'))
// const HomeStudio = lazy(() => import('./views/Home_studio/home'))
// const HomePerf = lazy(() => import('./views/Home_perf/home'))
const Register = lazy(() => import('./views/Register/register'))
const FirstPage = lazy(() => import('./views/ApiPath/firstPage'))
const CGU = lazy(() => import('./views/Legal/cgu'))
const Mentions = lazy(() => import('./views/Legal/mentions'))

const SuspenseRoute = props => {
    if (props.auth && !props.auth()) return <Redirect to='/login' />
    let { fallback, component: Component } = props
    let newProps = { ...props }
    delete newProps.fallback
    delete newProps.component
    return <Route {...newProps}>
        <Suspense fallback={fallback || <div style={{ height: "100%", width: "100%", zIndex: 1000, position: 'absolute', top: 0, left: 0, backgroundColor: 'white' }}></div>}>
            <Component {...newProps} />
        </Suspense>
    </Route>
}

const ScrollToTopOnPageChange = () => {
    const history = useHistory()
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        });
        return () => {
            unlisten();
        }
    }, []);
    return <></>
}


const Router = () => {
    const [context] = useMainContext()
    const logged = !!(context.user && sessionStorage.token)

    return (
        <BrowserRouter>
            <ScrollToTopOnPageChange />
            <DesignGuidelines />
            <Switch>
                <SuspenseRoute path='/app' component={Main} auth={() => logged} />
                <SuspenseRoute exact path='/register' component={Register} />
                <SuspenseRoute exact path='/join/:id' component={Join} />
                <SuspenseRoute path='/details*' component={FirstPage} />
                <SuspenseRoute exact path='/user/forgot_password/:session' component={Reset} />
                <SuspenseRoute exact path='/terms/cgu' component={CGU} />
                <SuspenseRoute exact path='/terms' component={Mentions} />
                <SuspenseRoute exact path={['/connect', '/api']} component={HomeAPI} />
                <SuspenseRoute exact path='/studio' component={HomeStudio} />
                <SuspenseRoute exact path='/performance' component={HomePerf} />
                <SuspenseRoute exact path={["/", "/home", "/login", "*"]} component={Home} />
            </Switch>
        </BrowserRouter>

    )
}

const App = () => {

    useEffect(() => {
        let meta = document.createElement('meta')
        meta.name = "facebook-domain-verification"
        meta.content = "xoahhzp4yiu43j9uy2pc5jc2m5hbmj"
        document.getElementsByTagName('head')[0].appendChild(meta)
    }, [])

    return (
        <Provider>
            <Router />
            <div id="popup-root" />
        </Provider>
    )
}

export default App
