import React, { useState } from 'react'
import './inputPass.scss'

const PassWordInput = ({ className, onChange, onClick, name = '', id, placeholder = '', onVisibilityChange, autocomplete = 'on', value, otherProps }) => {
    const [passwordVisibility, setVisibility] = useState(false)
    const f = a => a || console.log
    return <div className={'password-input ' + (className || '')}>
        <input onChange={f(onChange)} onClick={f(onClick)} type={passwordVisibility ? 'text' : 'password'} name={name} {...(id ? { id } : {})} {...(value !== undefined ? { value } : {})} {...(otherProps ? { otherProps } : {})} placeholder={placeholder} autoComplete={autocomplete} />
        <img src='/img/password.svg' onClick={() => setVisibility(a => !a) || f(onVisibilityChange)(!passwordVisibility)} />
    </div>
}

export default PassWordInput