import React from 'react'
import './Errors.scss'

const Errors = ({message, type}) => {

    return (
        <>
            <div className="error-box" style={{background: type ? "#b3cf42" : "#F40009"}}>
                <h1>{message}</h1>
            </div>
        </>
    )
}

export default Errors