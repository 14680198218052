const prod = {
    APISAILS_URL: 'https://apisails.timenjoy.fr',
    WEBSITE_URL: 'https://timenjoy.fr',
    CRAWLOSAURE_API: 'https://crawlosaure.api.timenjoy.fr'
};

const devt = {
    APISAILS_URL: 'https://apisails-dev.timenjoy.fr',
    WEBSITE_URL: 'https://timenjoy-dev.timenjoy.fr',
    CRAWLOSAURE_API: 'https://crawlosaure-dev.api.timenjoy.fr'
};

const local = {
    APISAILS_URL: 'http://localhost:1337',
    WEBSITE_URL: 'http://localhost:4300',
    CRAWLOSAURE_API: 'http://localhost:8787'
};

const config = {
    debut: process.env.REACT_APP_MODE !== 'production',
    ...(process.env.REACT_APP_MODE === 'local' ? local : process.env.REACT_APP_MODE === 'development' ? devt : prod)
}

export default config;

export const WEBSITE_URL = config.WEBSITE_URL
export const APISAILS_URL = config.APISAILS_URL;
export const CRAWLOSAURE_API = config.CRAWLOSAURE_API;