import { AES } from "crypto-js";
import { POST } from '../helpers/fetch'
import { APISAILS_URL } from "./Config"

const obj = {
    appId: process.env.REACT_APP_API_KEY,
    password: process.env.REACT_APP_API_PASS,
    expiredDate: new Date(Date.now() + 11800000).toISOString(),
};
const encrypted = AES.encrypt(JSON.stringify(obj), process.env.REACT_APP_ENCRYPT_SECRET).toString();
const data = {
    appId: encodeURIComponent(encrypted),
    password: "35b7009ec0"
}

function auth(){
    return POST(APISAILS_URL + "/auth/login", data)
    .then(r => {
        sessionStorage.setItem('token', r.token)
        return r.token
    })
    .catch(err=> {
        console.error(err)
        return new Promise(r=>setTimeout(()=>auth().then(r), 1000))
    })
}
export const authPromise = auth()

